<template>
  <!-- eslint-disable tailwindcss/no-custom-classname   -->
  <div
    class="relative"
    data-el="ui-blog-teaser-list"
  >
    <div
      class="block flex-wrap items-center py-7 lg:flex xl:flex-nowrap xl:pb-24 xl:pt-32"
    >
      <div class="z-10 w-full xl:w-1/4">
        <UiHeader
          v-if="props.header"
          :header-layout="headerLayout"
          :header="props.header"
          header-style-class="text-white text-xl xl:text-4xl mb-11 xl:mb-9"
        />
        <UiButton
          v-if="props.buttonLink && props.buttonText"
          class="!hidden !text-white xl:!inline-flex"
          variant="link"
          :to="props.buttonLink"
          icon-right="arrow"
        >
          {{ props.buttonText }}
        </UiButton>
      </div>
      <div class="relative -mr-8 lg:mr-0 lg:hidden">
        <UiSliderHorizontal
          class="swiper-main"
          classes="swiper-main"
          :slides-per-group="2"
        >
          <template
            v-for="listItem in list"
            :key="listItem.uid"
          >
            <UiBlogTeaser
              class="w-1/3 last:mr-8 xl:last:mr-0"
              :header="listItem.title"
              :gradient="gradient"
              :image="{
                src:
                  listItem.media?.[0]?.images?.defaultImage?.cropVariants
                    ?.expertListViewImage?.publicUrl
                  || listItem.media?.[0]?.images?.defaultImage?.publicUrl,
              }"
              :description="listItem.teaser"
              :button="{
                href: listItem.slug,
                title: listItem.moreLink,
              }"
              :author-firstname="listItem.author?.[0]?.firstname"
              :author-lastname="listItem.author?.[0]?.lastname"
              :author-position="listItem.author?.[0]?.position"
              :author-image="{
                src: listItem.author?.[0]?.image?.images?.defaultImage
                  ?.publicUrl,
                width:
                  listItem.author?.[0]?.image?.images?.defaultImage?.dimensions
                    .width,
                height:
                  listItem.author?.[0]?.image?.images?.defaultImage?.dimensions
                    .height,
              }"
            />
          </template>
        </UiSliderHorizontal>

        <div class="mr-8 pt-7">
          <UiButton
            v-if="props.buttonLink && props.buttonText"
            class="w-full border-white !text-white"
            variant="secondary"
            :to="props.buttonLink"
            icon-right="arrow"
          >
            {{ props.buttonText }}
          </UiButton>
        </div>
      </div>
      <div class="hidden gap-x-6 pb-5 pl-8 lg:flex lg:pl-1.5 xl:mx-0 xl:w-3/4">
        <UiBlogTeaser
          v-for="listItem in list"
          :key="listItem.uid"
          class="w-1/3 last:mr-8 lg:last:mr-0"
          :gradient="gradient"
          :header="listItem.title"
          :image="{
            src:
              listItem.media?.[0]?.images?.defaultImage?.cropVariants
                ?.expertListViewImage?.publicUrl
              || listItem.media?.[0]?.images?.defaultImage?.publicUrl,
          }"
          :description="listItem.teaser"
          :button="{
            href: listItem.slug,
            title: listItem.moreLink,
          }"
          :author-firstname="listItem.author?.[0]?.firstname"
          :author-lastname="listItem.author?.[0]?.lastname"
          :author-position="listItem.author?.[0]?.position"
          :author-image="{
            src: listItem.author?.[0]?.image?.images?.defaultImage?.publicUrl,
            width:
              listItem.author?.[0]?.image?.images?.defaultImage?.dimensions
                .width,
            height:
              listItem.author?.[0]?.image?.images?.defaultImage?.dimensions
                .height,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import UiHeader from '../UiHeader/UiHeader.vue'
import UiButton from '../UiButton/UiButton.vue'
import UiBlogTeaser from '../UiBlogTeaser/UiBlogTeaser.vue'
import type { UiHeaderProps } from '../UiHeader/useUiHeader'
import UiSliderHorizontal from '../UiSlider/UiSliderHorizontal.vue'
import type { UiBlogTeaserDetail } from './UiBlogTeaserList.types'

interface UiBlogTeaserList extends UiHeaderProps {
  list: UiBlogTeaserDetail[]
  buttonLink?: string
  buttonText?: string
  gradient?: string
}

const props = withDefaults(defineProps<UiBlogTeaserList>(), {
  list: () => [],
  buttonLink: '',
  buttonText: '',
  gradient: '',
})
</script>
